
    import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
    import { Product } from '../models/Product'
    import { FilterMixin } from '@/mixins/filterMixin';
    import filter from '@/store/modules/filter'
    import { RawLocation } from "vue-router";
    import azureProductSearch from '../services/azureSearchclient';

    @Component({})
    export default class SearchBox extends Mixins(FilterMixin) {

        products: Product[] = [];
        keyword = '';
        paginationParam = {
            page: 1,
            limit: 20
        };

        @Watch('$route', { deep: true })
        onRouteChange(value: any) {
            if(value.name == 'Search' && value.query.keyword)
            {
                this.keyword = value.query.keyword;
            }
            else {
                this.keyword = '';
            }
        }

        async submitData() {
            if (this.keyword && this.keyword !== "")
            {
                const searchResults: any = await azureProductSearch.search(this.fuzzyQuerySearch(this.keyword),
                {
                    includeTotalCount: true,
                    queryType: "full",
                    searchMode: "all",
                    top: this.paginationParam.limit,
                    skip: this.paginationParam.limit * (this.paginationParam.page - 1)
                });

                const products: Product[] = [];

                for await (const result of searchResults.results) {
                    const product = {
                        id: result.document["Id"],
                        sku: result.document["SKU"],
                        description: result.document["Description"],
                        mainImage: result.document["MainImage"],
                        bulletPoints: result.document["BulletPoints"],
                        additionalImages: result.document["AdditionalImages"],
                        friendlyUrl: result.document["FriendlyUrl"],
                        upc: result.document["UPC"],
                        collectionDescription: result.document["CollectionDescription"],
                        assemblyRequirements: result.document["AssemblyRequirements"],
                        ageLevel: result.document["AgeLevel"],
                        warranty: result.document["Warranty"],
                        filterId: result.document["FilterId"],
                        filterName: result.document["FilterName"],
                        filterFriendlyUrl: result.document["FilterFriendlyUrl"]
                    } as Product;

                    products.push(product);
                }

                const results = {
                    data: products,
                    total: searchResults.count
                }


                if (products.length == 1) {
                    let item = products[0];
                    let sub = filter.filters.find(x => x.friendlyUrl == item.filterFriendlyUrl);
                    let cat = filter.filters.find(x => x.id == sub?.parentId);
                    this.$router.push({ name: 'Product', params: { productSlug: item.friendlyUrl, categorySlug: cat?.friendlyUrl, subCategorySlug: sub?.friendlyUrl } } as RawLocation).catch(err => err);
                }
                else {
                    filter.saveProducts(results);
                    this.$router.push({ name: 'Search', query: { keyword: this.keyword } }).catch(err => err);
                }
            }
        }
    }
